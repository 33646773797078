<script>
import LoginMain from '@/components/main/LoginMain.vue';
import ElementsModal from '@/components/elements/ElementsModal.vue';

export default {
    components: {
        LoginMain,
        ElementsModal,
    },
    data() {
        return {
            result: false,
            items: ['Login'],
            loginRequestError: {
                loginId: '',
                loginPwd: '',
                message: '',
            },
            loginRequest: {
                selectPosition: 'admin',
                loginId: '',
                loginPwd: '',
            },
            noticePopup: false,
            axiosNoti: false,
        };
    },

    mounted() {
        // if(window.logOnProfile) location.href = '/settings/company_settings/company_information'
        this.initLoginRequest();
    },
    methods: {
        initLoginRequest() {
            //init
            const self = this;
            self.loginRequest.loginId = '';
            self.loginRequest.loginPwd = '';
        },
        isEmailOk(email) {
            //형식 && 비어있는지 체크
            const self = this;
            let lowString = email.toLowerCase().trim();
            const formatEmail = /^([0-9a-z!#$%&'*\\+\-/=?^_`{|}~]+(?:\.[0-9a-z!#$%&'*\\+\-/=?^_`{|}~]+)*)@([0-9a-z]+(?:-[0-9a-z]+)*(?:\.[0-9a-z]+)*)$/.test(lowString);
            if (!formatEmail || email == '') self.loginRequestError.loginId = 'Please enter a vaild email address';
            else {
                self.loginRequestError.loginId = '';
                return 0;
            }
        },
        isloginPwdOk(pwd) {
            //비밀번호가 입력되어 있는지 체크
            const self = this;
            if (pwd == '') {
                self.loginRequestError.loginPwd = 'Please provide your login password';
            } else {
                self.loginRequestError.loginPwd = '';
                return 0;
            }
        },
        errMsgReset() {
            const self = this;
            self.loginRequestError.loginId = '';
            self.loginRequestError.loginPwd = '';
        },
        isValid() {
            const self = this;

            //비어있는 갯수 체크
            let notVaild = 0;

            //이메일 형식 체크
            let lowString = self.loginRequest.loginId.toLowerCase().trim();
            const formatEmail = /^([0-9a-z!#$%&'*\\+\-/=?^_`{|}~]+(?:\.[0-9a-z!#$%&'*\\+\-/=?^_`{|}~]+)*)@([0-9a-z]+(?:-[0-9a-z]+)*(?:\.[0-9a-z]+)*)$/.test(lowString);

            //--------------
            if (!formatEmail) {
                self.loginRequestError.loginId = 'Please enter a vaild email address';
                notVaild += 1;
            }
            if (self.loginRequest.loginId == '') {
                self.loginRequestError.loginId = 'Please enter a vaild email address';
                notVaild += 1;
            }
            if (self.loginRequest.loginPwd == '') {
                self.loginRequestError.loginPwd = 'Please provide your login password';
                notVaild += 1;
            }
            //--------------

            return notVaild == 0;
        },

        login() {
            const self = this;

            //처음에 errMsgReset 해줌
            self.errMsgReset();

            //비어있는지 체크
            if (!self.isValid()) return;

            self.connect();
        },
        connect() {
            const self = this;
            const formProps = Object.fromEntries(new FormData(self.$refs['loginForm']));

            let url = self.$api('uri', 'post-sign-in');

            self.$axios
                .post(url, formProps)
                .then((res) => {
                    console.log(url + ':result', res);
                    location.href = '/hrDashboard';
                })
                .catch((err) => {
                    //억지로 포커스 해제
                    document.activeElement.blur();

                    //포커스 이동, 단 input 일때만 가능...
                    // self.$nextTick(() => self.$refs.noticePopup.focus());

                    if (err.response == undefined) {
                        self.axiosNoti = true;
                        self.loginRequestError.message = 'Network Error';
                        return console.error(err);
                    }

                    const status = err.response.status;
                    switch (true) {
                        case status == 400:
                            self.loginRequestError.message = "can't empty";
                            break;
                        case status == 403: {
                            self.noticePopup = true;
                            self.loginRequestError.message = 'You have entered an invalid username or password';
                            break;
                        }
                    }

                    // console.error(err);
                })
                .finally(() => {});
        },
        closeNoticePopup() {
            const self = this;

            self.noticePopup = false;
        },
        onSubmit(event) {
            event.preventDefault();
            this.login();
        },
    },
};
</script>

<template>
    <div id="app" class="min-w-[1024px] min-h-[100vh] flex">
        <LoginMain :items="items">
            <div id="login" class="w-full h-full flex">
                <div class="flex-1 bg-[url('/src/assets/img/login-3.jpg')] bg-cover">
                    <div class="w-full h-full flex justify-center items-center gap-4 bg-black/[0.6]">
                        <!-- <img src="@/assets/img/login-2.png" alt="login-2" />
                        <h1 class="text-5xl font-bold text-white">
                            HR Project
                        </h1> -->
                        <img src="@/assets/img/hrnflex_wh_logo.png" alt="white_logo" class="h-9" />
                    </div>
                </div>
                <form class="flex-1 flex flex-col justify-center items-center" @submit="onSubmit" ref="loginForm">
                    <h1 class="text-4xl font-semibold">Login</h1>
                    <!-- <ElementsSelect
                    class="mt-7"
                    v-model="loginRequest.selectPosition"
                    name="Select Position"
                    :options="selectPositionOptions"
                    @click="selectEmployee"
                    /> -->
                    <ElementsInput
                        class="mt-3.5"
                        v-model="loginRequest.loginId"
                        name="Email Address"
                        placeholder="Enter Email Address"
                        :error="loginRequestError.loginId"
                        inputName="loginId"
                        inputtype="email"
                        :autoComplete="'email'" />
                    <ElementsInput
                        class="mt-3.5"
                        v-model="loginRequest.loginPwd"
                        name="Password"
                        placeholder="Enter Password"
                        :error="loginRequestError.loginPwd"
                        inputName="loginPwd"
                        inputtype="password"
                        ref="password"
                        :autoComplete="'current-password'" />
                    <ElementsButton class="mt-8" text="Login" inputtype="submit" />
                    <a class="mt-10 text-sm font-semibold blu-text" href="/find_password">Forgot password?</a>
                </form>
            </div>
        </LoginMain>
    </div>
    <Teleport to="body">
        <ElementsModal v-model="noticePopup" :name="loginRequestError.message" button-text="OK" :warn="true" @button-event="closeNoticePopup" />
        <ElementsNotification v-model="axiosNoti" :notificationMsg="loginRequestError.message" :warning="axiosNoti" />
    </Teleport>
</template>
<style scoped>
.blu {
    background: #4361ee;
}
.blu:hover {
    background: #334cba;
}
.blu-text {
    color: #4361ee;
}
</style>
